import { useState, FunctionComponent, useEffect } from "react";
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import {
  activateGuardianAccount,
  getGuardiansByStatus,
  generateOnboardingLink,
} from "./utils";
import CircularLoader from "../CircularLoader";
import Button from "@mui/material/Button";
import AlertSnackBar from "../AlertSnackbar";
import CopyLink from "../CopyLink";
import HectareInput from "../HectareInput";
import { joinArrayOfStrings } from "../partner/PartnerListResults";

interface GuardianListResultProps {
  status: "interested" | "accountActivated" | "onboarded";
  scope: string;
}

export const capitalizeFirstLetter = (word: string) => {
  const capitalized = word.charAt(0).toUpperCase() + word.slice(1);
  return capitalized;
};

const GuardianListResult: FunctionComponent<GuardianListResultProps> = ({
  status,
  scope,
  ...rest
}) => {
  const [isHectareInputOpen, setHectareOpen] = useState(false);
  const [limit, setLimit] = useState(5);
  const [page, setPage] = useState(0);
  const [customerData, setCustomerData] = useState<any>("");
  const [count, setCount] = useState<any>();
  const [accountActSubmittingId, setAccountActSubmittingId] = useState<any>("");
  const [onboardingLinkSubmittingId, setOnboardingLinkSubmittingId] =
    useState<any>("");
  const [snackBarOpen, setSnackBarOpen] = useState<boolean>(false);
  const [snackBarData, setSnackBarData] = useState<any>();
  const [linkDialogOpen, setLinkDialogOpen] = useState<boolean>(false);
  const [onboardingLink, seOnboardingLink] = useState<string>("");

  const handleLimitChange = (event: any) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event: any, newPage: number) => {
    setPage(newPage);
  };

  const handleSubmitAccountActivate =
    (interestId: number) => async (setSubmitting: any, hectares: number) => {
      const response = await activateGuardianAccount(
        {
          interestId,
          hectares,
        },
        setAccountActSubmittingId,
        setSubmitting
      );
      if (response.status === "success") {
        setSnackBarData(response);
        setSnackBarOpen(true);
        const { data } = await getGuardiansByStatus({
          limit,
          page: page + 1,
          status,
        });
        setCustomerData(data.data);
        setCount(data.count);
      } else {
        setSnackBarData(response);
        setSnackBarOpen(true);
      }
      setHectareOpen(false);
    };

  const handleSubmitGenerateOnboardingLink =
    (accountRoleId: number) => async () => {
      const response = await generateOnboardingLink(
        { accountRoleId },
        setOnboardingLinkSubmittingId
      );
      if (response.status === "success") {
        seOnboardingLink(response.link);
        setLinkDialogOpen(true);
      } else {
        setSnackBarData(response);
        setSnackBarOpen(true);
      }
    };

  const handleOpenHectareInput = () => {
    setHectareOpen(true);
  };

  const handleCloseHectareInput = () => {
    setHectareOpen(false);
    setAccountActSubmittingId("");
  };

  const handleClickApprove = (interestId: number) => () => {
    setAccountActSubmittingId(interestId);
    handleOpenHectareInput();
  };

  useEffect(() => {
    (async () => {
      setCustomerData("");
      const { data } = await getGuardiansByStatus({
        limit,
        page: page + 1,
        status,
      });
      setCustomerData(data.data);
      setCount(data.count);
    })();
  }, [limit, page, status]);

  return customerData ? (
    <Card {...rest}>
      <Box>
        <HectareInput
          onSubmit={handleSubmitAccountActivate(accountActSubmittingId)}
          open={isHectareInputOpen}
          handleClose={handleCloseHectareInput}
          actor={"guardian"}
        />
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: 600, whiteSpace: "nowrap" }}>
                  First name
                </TableCell>
                <TableCell sx={{ fontWeight: 600, whiteSpace: "nowrap" }}>
                  Last name
                </TableCell>
                <TableCell sx={{ fontWeight: 600 }}>Email</TableCell>
                <TableCell sx={{ fontWeight: 600 }}>Role</TableCell>
                <TableCell sx={{ fontWeight: 600 }}>Region</TableCell>
                <TableCell sx={{ fontWeight: 600 }}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {customerData.map((customer: any) => (
                <TableRow hover key={customer.email}>
                  <TableCell>{customer.firstName}</TableCell>
                  <TableCell>{customer.lastName}</TableCell>
                  <TableCell>{customer.email}</TableCell>
                  <TableCell>
                    {customer.role
                      ? capitalizeFirstLetter(customer.role)
                      : joinArrayOfStrings(customer.roles)}
                  </TableCell>
                  <TableCell>{customer.region}{customer.country && `, ${customer.country}`}</TableCell>
                  <TableCell><a href={customer.interestDocument} target="_blank" rel="noreferrer">{customer.interestDocument?.substring(customer.interestDocument?.lastIndexOf('/') + 1)}</a></TableCell>
                  <TableCell align="right">
                    {scope === 'superAdmin' && (
                    status === "interested" ? (
                      <Button
                        variant="contained"
                        disabled={
                          accountActSubmittingId === customer.interestId
                        }
                        onClick={handleClickApprove(customer.interestId)}
                      >
                        Approve
                      </Button>
                    ) : status === "accountActivated" ? (
                      <Button
                        onClick={handleSubmitGenerateOnboardingLink(
                          customer.accountRoleId
                        )}
                        disabled={
                          onboardingLinkSubmittingId === customer.accountRoleId
                        }
                        variant="contained"
                      >
                        Generate link
                      </Button>
                    ) : null
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <TablePagination
        component="div"
        count={count}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 15]}
      />
      <AlertSnackBar
        open={snackBarOpen}
        severity={snackBarData?.status}
        message={snackBarData?.message}
        handleClose={() => setSnackBarOpen(false)}
      />
      <CopyLink
        link={onboardingLink}
        open={linkDialogOpen}
        setOpen={setLinkDialogOpen}
      />
    </Card>
  ) : (
    <CircularLoader />
  );
};

export default GuardianListResult;
